import React from "react";

const Intro6 = () => {
  return (
    <header className="mobile-app valign">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 valign">
            <div className="caption">
              <h1 className="mb-20">
                Let’s Build Amazing <span id="thething">Solutions</span> Together
              </h1>
              <p>
                The digital architects and evangelists at Creative Clarity will work with you 
                through every phase of the product development lifecycle to breath life into 
                your ideas.
              </p>
              <p>
                We have built social media plaforms, marketing campaigns and websites reaching millions. 
                Our embedded development teams are trusted with projects that reach worldwide audiences 
                generating millions in annual revenues. 
              </p>
            </div>
          </div>

          <div className="col-lg-5 offset-lg-1">
            <div className="img wow fadeIn">
              <img src="/img/mobile-app/header-img.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Intro6;
