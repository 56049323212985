import React from "react";
import Split from "../Split";
import { Link } from 'gatsby';
import { thumparallaxDown } from "../../common/thumparallax";

const MinimalArea2 = () => {
  React.useEffect(() => {
    setTimeout(() => {
      thumparallaxDown();
    }, 1000);
  }, []);
  return (
    <section className="min-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="img">
              <img
                className="thumparallax-down"
                src="/img/min-area.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 valign">
            <div className="content">
              <h4 className="color-font">Dream. Innovate. Implement. Repeat.</h4>
              <Split>
                <p className="wow txt words chars splitting" data-splitting>
                  Our digital product development agency is trusted by a wide variety of companies from across the globe to build BIG solutions.
                  We cultivate smart ideas for start-ups and seasoned teams, always innovating and always evolving. 
                  We are passionate about our clients, their digital projects and their goals. 
                  We are the enterprise software development partner you have been searching for.
                </p>
              </Split>
              <ul>
                <li className="wow fadeInUp" data-wow-delay=".2s">
                  Dediacted development teams working across all timezones.
                </li>
                <li className="wow fadeInUp" data-wow-delay=".4s">
                  Trusted by major brands and driven startups.
                </li>
              </ul>
              <Link to={`/about`} className="butn bord curve mt-40 wow fadeInUp" data-wow-delay=".8s">
                <span>Discover</span>
              </Link>

              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MinimalArea2;
