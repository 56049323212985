import React from "react";
import { Link } from 'gatsby';
import cardMouseEffect from "common/cardMouseEffect";

const Services3 = () => {
  React.useEffect(() => {
    cardMouseEffect(document.querySelectorAll(".feat .items"));
  }, []);
  return (
    <section className="feat sub-bg section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
                Your Partner in Growth
              </h6>
              <h3 className="wow color-font">
                We dont just build solutions, we build businesses.
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 items md-mb30">
            <div className="item wow fadeIn" data-wow-delay=".3s">
              <span className="icon">
                <i className="ion-ios-monitor"></i>
              </span>
              <h5>Interface Design</h5>
              <p>
                Developing novel and highly usable interfaces to better engage with your audiences.
              </p>
              <Link to="/about" className="more-stroke">
                <span></span>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 items active md-mb30">
            <div className="item wow fadeIn" data-wow-delay=".3s">
              <span className="icon">
                <i className="ion-ios-bolt-outline"></i>
              </span>
              <h5>Creative Always</h5>
              <p>
                We are constantly evolving to keep on the leading edge of digital technology trends.
              </p>
              <Link to="/about" className="more-stroke">
                <span></span>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 items sm-mb30">
            <div className="item wow fadeIn" data-wow-delay=".3s">
              <span className="icon">
                <i className="ion-chatbubbles"></i>
              </span>
              <h5>Clear Communication</h5>
              <p>
                We approach each project from best practices in process and communication to keep stakeholders informed and in control.
              </p>
              <Link to="/about" className="more-stroke">
                <span></span>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 items">
            <div className="item wow fadeIn" data-wow-delay=".3s">
              <span className="icon">
                <i className="ion-ios-color-wand"></i>
              </span>
              <h5>Ongoing Support</h5>
              <p>
                We have been working with many clients over a number of years to continually build on prior success and help reach new markets.
              </p>
              <Link to="/contact" className="more-stroke">
                <span></span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services3;
